import PaymentWallMethod from "./base.js";
import {includeMany} from "../utils.js";

const INVALID_DETAILS = [
  "payment_method_not_allowed",
  "invalid_payment_method",
  "authorization_declined"
];

export default class Azon extends PaymentWallMethod {
  name = "azon";

  async setupScripts() {}

  async setUp() {
    window.pwall.PayWithAmazon = this.finish;
    // Setup amazon scripts ** AFTER** on amazon payments ready has been set
    document.addEventListener('azovReady', () => {
      let settings = {
        clientId: this.userSettings.client_id,
        sellerId: this.userSettings.seller_id,
        ...this.userSettings,        ...this.settings.templates[0].settings
      }
      if(window.pwall.session){
        settings.logo = window.pwall.session.logo
      }else {
        settings.logo = window.pwall.logo
      }
      document.dispatchEvent(new CustomEvent('azovSettings', {detail: settings}));
    })

    includeMany(this.settings.scripts);
  }

  async finish(requestId, confirmationFlow) {
    // this.overlay.style.display = "block";
    if (!requestId) {
      requestId = this.paymentData.request_id;
    }
    window.pwall.callbacks.processing_payment();
    // TODO: Remove calls to window.pwall and use local instance
    const resp = await window.pwall.rpc.pwall.sale(
      {
        returnDirect: true,
        ...window.pwall.extraData,
        request_id: requestId,
        method: "azon"
      },
      true
    );

    if (resp.detail == "auth_required") {
      confirmationFlow.success();
      return false;
    } else if (resp.detail == "checkout_success") {
      window.pwall.callbacks.payment_ok({
        payload: {
          transaction_id: resp.payload.request_id,
          currency: resp.payload.currency,
          amount: resp.payload.amount
        }
      });
      return true;
    } else if (INVALID_DETAILS.includes(resp.detail)) {
      // Redraw ourselves as if the user just logged in.
      window.pwall.callbacks.payment_ko({error: resp.detail});
      return false;
    } else {
      window.pwall.callbacks.payment_ko({error: "Error"});
      return false;
    }
  }
}
