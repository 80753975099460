import mustache from "mustache";
import {includeMany} from "../utils.js";
import {translations} from "../languages.js";

/** This codes will be considered valid and launch a ok callback **/
const VALID_CODES = ["0", "1"];
const INVALID_MESSAGES = ["can_not_continue", "no_card_from_token"];

/**
 * @typedef PaymentWallExecutionResponse
 * @type {object}
 * @property {string} name - Payment Method name as in Sipay conventions (slug)
 * @property {PaymentWallElement} elements - List of rendered templates for this payment method.
 * @property {async} setup - Setup coroutine to be called after adding the elements to the DOM.
 */

/**
 * @typedef PaymentWallElement
 * @type {object}
 * @property {string} icon - Big icon to show on the payment wall
 * @property {string} method - Method (template name)
 * @property {string} inner_icon - Lateral icon to show on the payment wall
 * @property {string} order - Order to render this element into
 * @property {string} settings - Settings array as returned from getConfiguration RPC call
 * @property {string} html - Template (rendered with settings variables)
 */

/** Payment Wall Method
 *
 * @class PaymentWallMethod
 * @description Payment Wall Method. Represents each payment method
 * @exports PaymentWallMethod
 *
 */
export default class PaymentWallMethod {
  /** Represents a payment wall PAYMENT METHOD
   *
   * @description Payment Method base class
   * @param {object} methodSettings - Method Settings
   * @param {PaymentWall} paymentWall - Instance of payment wall this will be rendered
   *                                    into
   *
   */
  constructor(methodSettings, paymentWall) {
    this.paymentWall = paymentWall;
    this.settings = methodSettings["config"];
    this.userSettings = methodSettings["settings"];
    if (translations.hasOwnProperty(this.paymentWall.language)) {
      this.translations = translations[this.paymentWall.language];
      window.translations = translations;
      window.lang = this.paymentWall.language;
    } else {
      window.translations = translations;
      window.lang = "es";
      this.translations = translations['es'];
    }
  }

  /** Check if payment method is disabled.
   *
   * It will be used to compose the disabled array on pwall
   */
  async isDisabled() {
    return [];
  }

  /** Execute payment method flow
   *
   * This will sincronously load scripts, render templates and return the setup
   * method
   *
   * @return {PaymentWallExecutionResponse}
   */
  async execute() {
    try {

      if (!this.settings) {
        console.log(`Not loading ${this.name}, it's disabled`);
        return;
      }
      console.log(`Loading ${this.name}`)

      await this.setupScripts();

      const elements = [];

      for await (let elem of this.render(this.settings.templates)) {
        elements.push(elem);
      }

      return {
        name: this.name,
        elements: elements,
        setup: () => this.setUp(),
        fullMethod: this
      };
    } catch (err) {
      console.error(`Could not load ${this.name} (${err})`);
    }
  }

  /** Sinchronously load scripts.
   *
   * Wait for a series of coroutines chained to onload events to wait for a
   * series of scripts to load in a sync way
   *
   * @returns null
   */
  setupScripts() {
    console.log(`Loading scripts for ${this.name}`)
    console.log(this.settings.scripts)
    return includeMany(this.settings.scripts);
  }

  /** Do extra loading tasks.
   *
   *  Must be implemented on children
   * */
  async setUp() {
    throw new Error("Not Implemented");
  }

  /** Get custom payment data for a specific method
   *
   *  Must be implemented on children
   * */
  getPaymentData() {
    return this.paymentData;
  }

  /** Execute a payment against jsonrpc
   *
   * Response will be evaluated, valid codes are defined in {VALID_CODES}
   * payment_ok or payment_ko will be launched
   *
   * @returns callback execution result
   * */
  async pay() {
    const result = await this.paymentWall.rpc.pwall.sale({
      method: this.name,
      returnDirect: true,
      ...this.paymentWall.extraData,
      ...this.getPaymentData()
    });
    if (result.payload && result.payload.url) {
      await this.rpc.pwall.getExtraData({
        express: false,
        create_order: true
      });
      window.location = result.payload.url;
    } else if (
      VALID_CODES.includes(result.code) &&
      !INVALID_MESSAGES.includes(result.detail)
    ) {
      return this.paymentWall.callbacks.payment_ok(result);
    } else {
      return this.paymentWall.callbacks.payment_ko({error: result});
    }
  }

  /** Finish a payment
   *
   * This will try to pay, otherwise call the ko callback as a capture method
   * have failed
   *
   * @returns callback execution result
   * */
  async finish() {
    try {
      return await this.pay();
    } catch (err) {
      console.error(
        `Could not finish payment on ${this.name}`,
        err ? err.stack : err
      );
      return this.paymentWall.callbacks.ko({error: err});
    }
  }

  /** Render templates with mustache, only non-disabled templates
   *
   * @returns {PaymentWallElement}
   * */
  async *render(templates) {
    const disabled = await this.isDisabled();
    for (let template of templates) {
      const template_enabled =
        !disabled.includes(template.name) && template.settings.enabled;

      if (this.paymentWall.backoffice || template_enabled) {
        console.debug(`[${template.name}] - rendering`);
        yield {
          method: template.name,
          text: template.text,
          icon: template.icon.replace("LANG", window.lang),
          inner_icon: template.icon_small,
          order: template.settings.order,
          enabled: template.settings.enabled,
          color: template.settings.color,
          size: template.settings.size,
          settings: this.settings,
          userSettings: this.userSettings,
          html: mustache.render(template.html, {
            t: this.translations,
            settings: this.settings,
            userSettings: this.userSettings,
            template: template
          })
        };
      }
    }
  }
}
