import PaymentWallMethod from "./base.js";
import {includeMany} from "../utils.js"

/** Payment wall Paypal Express Checkout */
export default class AltpPaypalExpressCheckout extends PaymentWallMethod {
  name = "altp_paypal_express_checkout";

  isDisabled() {
    // On express checkout, display the new altp_ppal method
    if (this.paymentWall.theme == 'ExpressCheckout') {
      return ["altp_paypal_express_checkout"];
    } else {
      return ['altp_ppal'];
    }
  }

  /** No setup required for redirect methods */
  async setUp() {
    if (this.paymentWall.theme != 'ExpressCheckout') {
      return;
    }
    this.settings.scripts[0].src = this.settings.scripts[0].src.replace('{{client-id}}', this.userSettings.express_checkout_client_id);
    await includeMany(this.settings.scripts);
    const localSettings = this.settings.templates[0].settings;
    const style = {
      size: localSettings.size || 'responsive',
      layout: localSettings.layout || 'vertical',
      color: localSettings.color || 'gold',
      shape: localSettings.shape || 'rect',
      label: localSettings.label || 'checkout'
    }

    document.addEventListener("payment_wall_retry", function () {
      console.log("Retrying")
    }.bind(this));

    paypal.Buttons({
      createOrder: async (actions) => {
        try {
          const response = await this.paymentWall.rpc.pwall.getExtraData({
            method: 'paypal',
            express: true,
            create_order: true,
            ...this.paymentWall.cartData
          });
          this.paymentData = {request_id: response.result.request_id};
          if (!response || response.error) {
            window.pwall.callbacks.payment_ko(response)
          }
          return response.result.order_id;
        } catch (e) {
          window.pwall.callbacks.payment_ko(response)
        }
      },
      onApprove: async () => {
        try {
          const result = await this.paymentWall.rpc.pwall.sale({
            method: 'paypal',
            ...this.paymentData,
          });
          if (!result || result.error) {
            window.pwall.placeholder.dispatchEvent(new Event("payment_wall_stop_processing_payment"))
            return this.paymentWall.callbacks.payment_ko();
          }
          return this.paymentWall.callbacks.payment_ok(result);
        } catch (e) {
          window.pwall.placeholder.dispatchEvent(new Event("payment_wall_stop_processing_payment"))
          return this.paymentWall.callbacks.payment_ko();
        }
      },
      onError: async () => {
        this.paymentWall.callbacks.payment_ko();
        this.paymentWall.placeholder.dispatchEvent("payment_wall_stop_processing_payment");
      },
      onCancel: async () => {
        window.pwall.placeholder.dispatchEvent(new Event("payment_wall_stop_processing_payment"))
        // Not doing anything ATM.
      },
      onShippingChange: async (data, actions) => {
        if (window.pwall) {
          try {
            const response = await window.pwall.rpc.pwall.getExtraData({
              // Retrieve current address.
              method: "paypal",
              ...this.paymentData,
              address: {
                country_code: data.shipping_address.country_code,
                county: data.shipping_address.state,
                city: data.shipping_address.city,
                zip: data.shipping_address.postal_code,
              },
            });

            if (response.amount != this.amount && !response.error) {
              await window.pwall.rpc.pwall.getExtraData({
                method: "paypal",
                update_amount: true,
                address: {
                  country_code: data.shipping_address.country_code,
                  county: data.shipping_address.state,
                  city: data.shipping_address.city,
                  zip: data.shipping_address.postal_code,
                },
                ...this.paymentData,
              });
            }

            if (!response || response.error) {
              console.log("error internal catched")
              actions.reject();
            } else {
              actions.resolve();
            }
          } catch (err) {
            console.log('error catched');
            actions.reject();
          }
        } else {
          actions.resolve()
        }
      },
      onClick: function (data, actions) {
        if (window.pwall.validationFunc && window.pwall.validationFunc instanceof Function) {
          if (window.pwall.validationFunc()) {
            if (window.pwall.placeholder && window.pwall.placeholder.dispatchEvent instanceof Function) {
              window.pwall.placeholder.dispatchEvent(new Event("payment_wall_processing_payment"))
            }
            return actions.resolve();
          } else {
            if (window.pwall.placeholder && window.pwall.placeholder.dispatchEvent instanceof Function) {
              window.pwall.placeholder.dispatchEvent(new Event("payment_wall_stop_processing_payment"))
            }
            return actions.reject()
          }
        } else {
          if (window.pwall.placeholder && window.pwall.placeholder.dispatchEvent instanceof Function) {
            window.pwall.placeholder.dispatchEvent(new Event("payment_wall_processing_payment"))
          }
          return actions.resolve()
        }
      },
      style: style
    }).render('#paypal-button-container');
  }

  /**
   * Overwrite this function to prevent scripts to be loaded before setUp()
   * call.
   */
  setupScripts() {}
}
