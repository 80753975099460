export const en = {
  'bizum_image': 'Buy with ',
  'bizum_title': 'If have your phone, you have cash',
  'bizum': 'With bizum you can buy online in the easiest way. Just provide your phone number and confirm the operation',
  'use_another_card': 'Use another card',
  'apay_title': 'The easiest way to pay on the web',
  'apay': 'If have an apple pay account, forget about filling interminable forms. Pay with touch id with your iphone or ipad with one click trough mac',
  'uppercase_total': 'TOTAL',
  'gpay': 'If you have a Google pay account, check "Buy with GPay" and spend less time on your payments',
  'gpay_title': 'An even better way to pay',
  'paypal': 'If you already have a PayPal account, click Buy now, we\'ll ask for your user and password. After filling in your data, you\'ll be redirected to the purchase confirmation page',
  'paypal_buy_with': 'Buy with',
  'paypal_title': 'Fastest and safest way to pay',
  'expiration_date': 'Expiration date',
  'name': 'Name',
  'pay_with_this_card': 'Pay with this card',
  'inespay_title': 'If you have an account on Inespay, you have money',
  'inespay': 'If you already have an account on inespay, click Continue and we\'ll finish the purchase process',
  'kakaopay_title': 'If you have an account on Kakaopay, you have money',
  'kakaopay': 'If you already have an account on Kakaopay, click Continue and we\'ll finish the purchase process.',
  'wechatpay_title': 'If you have an account on WeChatPay, you have money',
  'wechatpay': 'If you already have an account on WeChatPay, click Continue and we\'ll finish the purchase process.',
  'finanpay': 'You can get financing up to 30.000€ in 60 months just by providing your phone number',
  'finanpay_title': 'A safe and secure payment method',
  'continue': 'Continue'
};
