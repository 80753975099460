import PaymentWallMethod from "./base.js";

/** Finanpay payment wall method
 *
 * @class Finanpay
 * @description Implementation of Finanpay for the payment wall
 * @exports Finanpay
 */

export default class Finanpay extends PaymentWallMethod {
  /** Setup nemuru */
  async setUp() {
    const params = { scriptUri: this.settings.scripts[0].local_src, clientId: this.settings.scripts[0].attributes['clientId'], configuration: { currency: 'EUR', decimalSeparator: ',', thousandSeparator: '.', locale: 'es-ES', }, onError: (error) => console.log({ error })}; (function (i, s, o, g, r, a, m) { let n, t; let p = false; n = s.createElement(o); n.type = 'text/javascript'; n.src = g.scriptUri; n.async = true; n.onload = n.onreadystatechange = async function () { if (!p && (!this.readyState || this.readyState == 'complete')) { p = true; Nemuru.init(g) } }; t = s.getElementsByTagName(o)[0]; t.parentNode.insertBefore(n, t); })(window, document, 'script', params);

    window.pwall.payWithNemuru = function(orderId, accessToken) {
    console.log(orderId);
    console.log(accessToken);
    window.Nemuru.checkout({
        container: "nemuru-checkout",
        orderId: orderId,
        accessToken: accessToken,
        style: {
            color: "#4CC6CD",
            branding: "finanpay"
        },
        onStatusChange: (status) => {
          window.pwall.rpc.pwall.notifyEvent({name: 'nemuru_status_change', extra_data: {'status': status}})
        },
        onClose: (status) => {
          if (status == 'confirmed') {
            window.pwall.callbacks.payment_ok();
          }

          if (status === 'denied') {
            window.pwall.callbacks.payment_ko();
          }
        },
        onError: (error) => {
            // do something on error
            window.pwall.callbacks.payment_ko();
            console.log(error);
        },
    });
    }
  }

  name = "finanpay";

  /** Execute a payment against jsonrpc
   *
   * Response will be evaluated, valid codes are defined in {VALID_CODES}
   * payment_ok or payment_ko will be launched
   *
   * @returns callback execution result
   * */
  async pay() {
    window.pwall.PayWithNemuru()

  }
}
