import PaymentWallMethod from "./base.js";
import CTP from './ctp.js'
import CTP_Mastercard from './ctp_mastercard.js'

const VALID_CODES = ["0"];
const INVALID_MESSAGES = ["can_not_continue"];

/** FastPay payment wall method
 * @class FPay
 * @description Implementation of FastPay for the payment wall
 * @exports FPay
 */

export default class FPay extends PaymentWallMethod {
  /** SetUp for FastPay. load styles and setup all fastpay buttons.
   *
   * TODO: Make the querySelector ONLY on our own children.
   */
  name = "fpay";
  paymentData = "";
  /** Execute a payment against jsonrpc
   *
   * Response will be evaluated, valid codes are defined in {VALID_CODES}
   * payment_ok or payment_ko will be launched
   *
   * @returns callback execution result
   * */
  async pay() {
    if (this.paymentWall.catcher) {
      this.paymentWall.callbacks.token_created(this.getPaymentData())
    } else {
      const result = await this.paymentWall.rpc.pwall.sale({
        method: this.name,
        returnDirect: true,
        ...this.paymentWall.extraData,
        ...this.getPaymentData()
      });

      if (result.payload && result.payload.url) {
        try {
          await this.paymentWall.rpc.pwall.getExtraData({
            express: false,
            create_order: true
          });
        } catch (err) {
          console.error(err);
        }
        window.location = result.payload.url;
      } else if (result.payload && result.payload.code == "198") {
        // DCC Offered, send corresponding event.
        this.paymentData = {
          request_id: result.request_id,
          payload: result.payload ? result.payload : {},
          dcc: true
        };
        this.setUp()
        return this.paymentWall.callbacks.offers_dcc(result);
      } else if (
        ((result.payload && VALID_CODES.includes(result.payload.code)) ||
          VALID_CODES.includes(result.code)) &&
        !INVALID_MESSAGES.includes(result.detail)
      ) {
        if (result.detail == 'dcc') {
          return this.paymentWall.callbacks.payment_ok_dcc(result);
        } else {
          if (result.detail == 'authentication') {
            // Authentication without URL, directly send a confirmation to those.
            // this COULD be any mit-like operations, but it doesn't make sense
            // to make mit operations from pwall, so this can only be CTP.
            this.paymentData = {
              request_id: result.payload.request_id,
              method: 'pwall_3ds'
            }
            if (!this.paymentWall.sessionLoaded || this.paymentWall.sessionType == 'auto') {
              this.processingPayment();
              return await this.finish()
            }
          }
          if(result.payload.ctp_type && result.payload.ctp_type == 'visa'){
            let ctpNewUser = localStorage.getItem('ctpNewUser');
            if(ctpNewUser){
              localStorage.removeItem('ctpNewUser');
              const iframeTecs = document.getElementById("tecsIframe").contentWindow;
              iframeTecs.postMessage(JSON.stringify({ action: "tecs_ctp_payment_ok" }), "*");
            }
          }
          return this.paymentWall.callbacks.payment_ok(result);
        }
      } else {
        return this.paymentWall.callbacks.payment_ko({ error: result });
      }
    }
  }

  setUp() {
    console.log(`Setting up fpay`)
    console.log(this.userSettings.ctp)
    let tokenization = this.userSettings.tokenization;
    let cards = this.userSettings.cards;
    let key = this.userSettings.key;
    let dcc = null;

    if (this.userSettings.ctp) {
      if (!this.ctp) {
        const type = this.userSettings.ctp.type.split("_")[1]
        switch(type){
          case "visa":
            this.ctp = new CTP(this.userSettings.ctp, this.userSettings.key, this)
            break;
          case "mastercard":
            this.ctp = new CTP_Mastercard(this.userSettings.ctp, this.userSettings.key, this)
            break;
          default:
            this.ctp = new CTP(this.userSettings.ctp, this.userSettings.key, this)
        }
      }
      this.ctp.setUp().then(() => console.log('Set up ctp'))
    }

    if (this.paymentData && this.paymentData.dcc) {
      dcc = {
        amount: this.paymentData.payload.amount,
        dcc_amount: this.paymentData.payload.dcc_amount,
        dcc_currency_used: this.paymentData.payload.dcc_currency_used,
        dcc_currency_offered: this.paymentData.payload.dcc_currency_offered,
        dcc_amount_converted: this.paymentData.payload.dcc_amount_converted,
        dcc_exchange: this.paymentData.payload.dcc_exchange,
        dcc_tax: this.paymentData.payload.dcc_tax,
        dcc_merchant: this.paymentData.payload.dcc_merchant,
        dcc_bce_exchange_rate: this.paymentData.payload.dcc_bce_exchange_rate,
        dcc_bce_margin: this.paymentData.payload.dcc_bce_margin,
        card_brand: this.paymentData.payload.card_brand
      }
    }
    const data = { action: "set_tecs_settings", cards: cards, key: key, dcc: dcc, tokenization: tokenization, cp: this.userSettings.ctp };
    this.sendMessage(data);

  }

  processingPayment() {
    this.paymentWall.callbacks.processing_payment();
  };

  completePayment(data) {
    if (Object.keys(data).includes("selection")) {
      this.paymentData.selection = data.selection;
    } else {
      this.paymentData = {
        request_id: data.request_id,
        payload: data.payload
      };
    }
    this.finish().then(() => {
      console.log("[fpay] Finished payment");
    });
  };

  dccSendFinalConfirmation(result) {
    return this.paymentWall.callbacks.payment_ok(result);
  }

  errorPayment(data) {
    this.paymentWall.callbacks.payment_ko({ error: data });
  }

  /**
   * Delete tokenId and send response to tecsIframe
   */
  async deleteToken(tokenId) {
    const response = await this.paymentWall.rpc.pwall.deleteToken({
      token_id: tokenId
    });
    if (response.code === "0") {
      this.userSettings.cards = this.userSettings.cards.filter(card => card.token_id != tokenId);
    }
    const data = { action: "deleted_token", tokenId: tokenId, response: response };
    this.sendMessage(data);
  }

  sendMessage(info) {
    const data = JSON.stringify(info);
    const iframeTecs = document.getElementById("tecsIframe").contentWindow;
    iframeTecs.postMessage(data, "*");
  }
}
