import PaymentWallMethod from "./base.js";
const VALID_CODES = ["0"];

/** Altpbzum payment wall method
 * @class AltpBzum
 * @description Implementation of Altpbzum for the payment wall
 * @exports AltpBzum
 */

export default class Altpbzum extends PaymentWallMethod {
  /** SetUp for altp_bizum. Nothing special needs
   *  to be done */
  async setUp() {}

  name = "altp_bizum";

  /** Execute a payment against jsonrpc
   *
   * Response will be evaluated, valid codes are defined in {VALID_CODES}
   * payment_ok or payment_ko will be launched
   *
   * @returns callback execution result
   * */
  async pay() {
    const result = await this.paymentWall.rpc.pwall.sale({
      method: this.name,
      returnDirect: true,
      ...this.paymentWall.extraData,
      ...this.getPaymentData()
    });

    if (VALID_CODES.includes(result.code)) {
      return this.paymentWall.callbacks.payment_ok(result.response);
    } else {
      return this.paymentWall.callbacks.payment_ko({ error: result });
    }
  }
}
