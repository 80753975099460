export const es = {
  'bizum_image': 'Comprar con ',
  'bizum_title': 'Si llevas el móvil, llevas dinero.',
  'bizum': 'Con Bizum podrás realizar tus compras online de forma más fácil y cómoda. Solo tendrás que indicar tu teléfono móvil, validar la operación y la compra se habrá realizado.',
  'use_another_card': 'Utilizar otra tarjeta',
  'apay_title': 'La forma más fácil de pagar en la web',
  'apay': 'Si tienes cuenta con Apple Pay, olvídate de rellenar formularios interminables. Puedes pagar Touch ID usando el iphone o el iPad o con un click a través del Mac.',
  'uppercase_total': 'TOTAL',
  'gpay': 'Si tienes cuenta de Google Pay, pulsa \"Comprar con GPay\" y pasa menos tiempo pagando.',
  'gpay_title': 'Una forma de pagar aún mejor',
  'paypal': 'Si ya tienes cuenta PayPal pulsa Comprar con donde te solicitaremos tu usuario y contraseña. Tras completar tus datos en sus páginas, serás redirigido a la página de confirmación de compra',
  'paypal_buy_with': 'Comprar con',
  'paypal_title': 'La forma rápida y segura de pagar',
  'expiration_date': 'Fecha de expiración',
  'name': 'Nombre',
  'pay_with_this_card': 'Pagar con esta tarjeta',
  'inespay_title': 'Si tienes cuenta Inespay, tienes dinero',
  'inespay': 'Si ya tienes cuenta inespay, pulsa \"Continuar\" y seguiremos con el proceso de compra',
  'kakaopay_title': 'Si tienes cuenta Kakaopay, tienes dinero',
  'kakaopay': 'Si ya tienes cuenta Kakaopay, pulsa \"Continuar\" y seguiremos con el proceso de compra.',
  'wechatpay_title': 'Si tienes cuenta WeChatPay, tienes dinero',
  'wechatpay': 'Si ya tienes cuenta WeChatPay, pulsa \"Continuar\" y seguiremos con el proceso de compra.',
  'finanpay': 'Podrás obtener financiación hasta 30.000€ en 60 meses unicamente facilitando tu número de teléfono',
  'finanpay_title': 'Una forma rápida y segura de pagar',
  'continue': 'Continuar'
};
