export const CURRENCIES = [
  ["AED", "784", 2],
  ["AFN", "971", 2],
  ["ALL", "008", 2],
  ["AMD", "051", 2],
  ["ANG", "532", 2],
  ["AOA", "973", 2],
  ["ARS", "032", 2],
  ["AUD", "036", 2],
  ["AWG", "533", 2],
  ["AZN", "944", 2],
  ["BAM", "977", 2],
  ["BBD", "052", 2],
  ["BDT", "050", 2],
  ["BGN", "975", 2],
  ["BHD", "048", 3],
  ["BIF", "108", 0],
  ["BMD", "060", 2],
  ["BND", "096", 2],
  ["BOB", "068", 2],
  ["BOV", "984", 2],
  ["BRL", "986", 2],
  ["BSD", "044", 2],
  ["BTN", "064", 2],
  ["BWP", "072", 2],
  ["BYN", "933", 2],
  ["BZD", "084", 2],
  ["CAD", "124", 2],
  ["CDF", "976", 2],
  ["CHE", "947", 2],
  ["CHF", "756", 2],
  ["CHW", "948", 2],
  ["CLF", "990", 4],
  ["CLP", "152", 0],
  ["CNY", "156", 2],
  ["COP", "170", 2],
  ["COU", "970", 2],
  ["CRC", "188", 2],
  ["CUC", "931", 2],
  ["CUP", "192", 2],
  ["CVE", "132", 2],
  ["CZK", "203", 2],
  ["DJF", "262", 0],
  ["DKK", "208", 2],
  ["DOP", "214", 2],
  ["DZD", "012", 2],
  ["EGP", "818", 2],
  ["ERN", "232", 2],
  ["ETB", "230", 2],
  ["EUR", "978", 2],
  ["FJD", "242", 2],
  ["FKP", "238", 2],
  ["GBP", "826", 2],
  ["GEL", "981", 2],
  ["GHS", "936", 2],
  ["GIP", "292", 2],
  ["GMD", "270", 2],
  ["GNF", "324", 0],
  ["GTQ", "320", 2],
  ["GYD", "328", 2],
  ["HKD", "344", 2],
  ["HNL", "340", 2],
  ["HRK", "191", 2],
  ["HTG", "332", 2],
  ["HUF", "348", 2],
  ["IDR", "360", 2],
  ["ILS", "376", 2],
  ["INR", "356", 2],
  ["IQD", "368", 3],
  ["IRR", "364", 2],
  ["ISK", "352", 0],
  ["JMD", "388", 2],
  ["JOD", "400", 3],
  ["JPY", "392", 0],
  ["KES", "404", 2],
  ["KGS", "417", 2],
  ["KHR", "116", 2],
  ["KMF", "174", 0],
  ["KPW", "408", 2],
  ["KRW", "410", 0],
  ["KWD", "414", 3],
  ["KYD", "136", 2],
  ["KZT", "398", 2],
  ["LAK", "418", 2],
  ["LBP", "422", 2],
  ["LKR", "144", 2],
  ["LRD", "430", 2],
  ["LSL", "426", 2],
  ["LYD", "434", 3],
  ["MAD", "504", 2],
  ["MDL", "498", 2],
  ["MGA", "969", 2],
  ["MKD", "807", 2],
  ["MMK", "104", 2],
  ["MNT", "496", 2],
  ["MOP", "446", 2],
  ["MRU", "929", 2],
  ["MUR", "480", 2],
  ["MVR", "462", 2],
  ["MWK", "454", 2],
  ["MXN", "484", 2],
  ["MXV", "979", 2],
  ["MYR", "458", 2],
  ["MZN", "943", 2],
  ["NAD", "516", 2],
  ["NGN", "566", 2],
  ["NIO", "558", 2],
  ["NOK", "578", 2],
  ["NPR", "524", 2],
  ["NZD", "554", 2],
  ["OMR", "512", 3],
  ["PAB", "590", 2],
  ["PEN", "604", 2],
  ["PGK", "598", 2],
  ["PHP", "608", 2],
  ["PKR", "586", 2],
  ["PLN", "985", 2],
  ["PYG", "600", 0],
  ["QAR", "634", 2],
  ["RON", "946", 2],
  ["RSD", "941", 2],
  ["RUB", "643", 2],
  ["RWF", "646", 0],
  ["SAR", "682", 2],
  ["SBD", "090", 2],
  ["SCR", "690", 2],
  ["SDG", "938", 2],
  ["SEK", "752", 2],
  ["SGD", "702", 2],
  ["SHP", "654", 2],
  ["SLL", "694", 2],
  ["SOS", "706", 2],
  ["SRD", "968", 2],
  ["SSP", "728", 2],
  ["STN", "930", 2],
  ["SVC", "222", 2],
  ["SYP", "760", 2],
  ["SZL", "748", 2],
  ["THB", "764", 2],
  ["TJS", "972", 2],
  ["TMT", "934", 2],
  ["TND", "788", 3],
  ["TOP", "776", 2],
  ["TRY", "949", 2],
  ["TTD", "780", 2],
  ["TWD", "901", 2],
  ["TZS", "834", 2],
  ["UAH", "980", 2],
  ["UGX", "800", 0],
  ["USD", "840", 2],
  ["USN", "997", 2],
  ["UYI", "940", 0],
  ["UYU", "858", 2],
  ["UYW", "927", 4],
  ["UZS", "860", 2],
  ["VES", "928", 2],
  ["VND", "704", 0],
  ["VUV", "548", 0],
  ["WST", "882", 2],
  ["XAF", "950", 0],
  ["XCD", "951", 2],
  ["XOF", "952", 0],
  ["XPF", "953", 0],
  ["YER", "886", 2],
  ["ZAR", "710", 2],
  ["ZMW", "967", 2],
  ["ZWL", "932", 2]
]
  .map(function(a) {
    var res = {};
    res[a[0]] = { decimals: a[2], isonum: a[1] };
    return res;
  })
  .reduce((a, b) => Object.assign(a, b));

/** Toggle element visibility, requires a class "visible" */
export function toggle(selector) {
  try {
    console.log(`[pwall_sdk] toggling visibility on ${selector}`);
    document.querySelector(selector).classList.toggle("hidden");
  } catch {
    console.error(`Can't find ${selector} on DOM`);
  }
}

/** Get a cookie value from its name */
export function getCookie(cname) {
  return document.cookie.replace(
    RegExp("(?:(?:^|.*;\\s*)" + cname + "\\s*\\=\\s*([^;]*).*$)|^.*$"),
    "$1"
  );
}

/** Include css
 *
 * This will include css asynchronously
 *
 * @param {string} file - Source to include
 * @param {object} extraAttrs - Extra attributes that will be set to the script
 *                              tag.
 *
 */
export function includeCss(file, extraAttrs) {
  return new Promise(function(resolve, reject) {
    var script = document.createElement("link");
    script.href = file;
    script.rel = "stylesheet";
    script.defer = true;

    // Set attributes
    for (let arg in extraAttrs) {
      script.setAttribute(arg, extraAttrs[arg]);
    }

    document
      .getElementsByTagName("head")
      .item(0)
      .appendChild(script);

    script.onload = function() {
      resolve();
    };
    script.onerror = function(event) {
      reject(event);
    };
  });
}



/** Include a scripts.
 *
 * This will include a scripts asynchronously
 *
 * @param {string} file - Source to include
 * @param {object} extraAttrs - Extra attributes that will be set to the script
 *                              tag.
 *
 */
export function include(file, extraAttrs, dataSet) {
  return new Promise(function(resolve, reject) {
    var script = document.createElement("script");
    script.src = file;
    script.type = "text/javascript";
    script.defer = true;

    // Set attributes
    if (extraAttrs) {
      for (let arg in extraAttrs) {
        script.setAttribute(arg, extraAttrs[arg]);
      }
    }

    if (dataSet) {
      for (const [key, value] of Object.entries(dataSet)) { script.dataset[key] = value; }
    }

    document
      .getElementsByTagName("head")
      .item(0)
      .appendChild(script);

    script.onload = function() {
      resolve();
    };
    script.onerror = function(event) {
      reject(event);
    };
  });
}

/**
 * @typedef PwallConfiguration
 * @type {object}
 * @property {string} name - Script name for reference
 * @property {object} attributes - Script attributes dictionary, will be
 *                    assigned as direct attributes.
 * @property {string} src - Script source
 */

/** Include many scripts.
 *
 * This will include scripts asynchronously, logging gracefully if one can't be
 * loaded.
 *
 * @param <List>.{ScriptFile} files - List of files to show
 *
 */
export async function includeMany(files) {
  for (let file of files) {
    let name = file["name"];
    try {
      await include(file["src"], file["attributes"]);
    } catch (error) {
      console.error(`Unable to load remote script ${name} (${file["src"]})`);
      throw error;
    }
  }
}

/** Transform to decimal value a normal currency+value pair
 *
 * @param {string} value - Value (10.01)
 * @param {string} currency - Currency as ISO4117 (EUR)
 *
 * @return {string} Value in decimal ("1001")
 * */
export function transformDecimals(value, currency) {
  return parseInt(value) / 10 ** CURRENCIES[currency].decimals;
}

export class AsyncLock {
  constructor() {
    this.disable = () => { }
    this.promise = Promise.resolve()
  }

  enable() {
    this.promise = new Promise(resolve => this.disable = resolve)
  }
}

export function buildId(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
