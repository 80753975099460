import "regenerator-runtime/runtime";
import "@babel/polyfill";

const axios = require("axios");
import APay from "./elements/apple_pay.js";
import GPay from "./elements/google_pay.js";
import Azon from "./elements/azei.js";
import FPay from "./elements/fastpay.js";
import AltpBkia from "./elements/altp_bankia_redirect.js";
import AltpPaypalExpressCheckout from "./elements/altp_paypal_express_checkout.js";
import AltpBzum from "./elements/altp_bizum.js";
import AltpAlipay from "./elements/alipay.js";
import AltpInespay from "./elements/inespay.js";
import AltpKakaoPay from "./elements/kakaopay";
import AltpWeChatPay from "./elements/wechatpay";
import Finanpay from "./elements/finanpay.js";
import Spay from "./elements/samsung_pay.js";
import PaymentWallRPC from "./rpc.js";
import { includeCss, include } from "./utils";

const NOT_DRAWABLE = ["request_id", "token_wallet"];

/**
 * @typedef PwallConfiguration
 * @type {object}
 * @property {string} [url=/] - URL to send JSON-RPC calls to.
 * @property {string} [endpoint=/] - Final endpoint (optional, defaults to /)
 *                                   to append to url
 * @property {number} [amount=0] - Amount as integer with the currency decimals
 * @property {number} [currency=EUR] - Currency as per ISO4217
 * @property {number} [version=1] - Payment Wall version
 * @property {string} [placeholder=#pwall_placeholder] - placeholder that will
 *   be used to draw the wall. Will be passed to querySelector
 */

/**
 * @typedef PwallEvents

 * @type {object}
 *
 * @property {CustomEvent} load - Payment Wall will listen to this event
 *                                before firing its draw methods.
 * @property {CustomEvent} loaded - Payment Wall will fire this event after
 *                                  load() has been called, and it has attached
 *                                  itself to the element. You MUST listen to
 *                                  this event before firing up set_extra_data
 *                                  or set_extra_headers.
 *                                  before firing its draw methods.
 *
 * @property {CustomEvent} setup - Notify document that pwall has been set-up
 *                                 and is ready for, for example, ok/ko
 *                                 callbacks on altps
 * @property {CustomEvent} drawn - Last event on the drawing phase of the payment wall
 *                                   Will be fired upon payment wall having retrieved
 *                                   all the payment methods available for it, loaded
 *                                   all the required scripts, and fired up all the
 *                                   required setup methods, it will contain
 *                                   the rendered elements as per render()
 *                                   response as customevent's data.
 * @property {CustomEvent} payment_ok - Payment finished with OK status.
 * @property {CustomEvent} payment_ko - Payment finished with KO status.
 * @property {CustomEvent} offers_dcc - Client has been offered DCC, show dcc choice.
 * @property {CustomEvent} process_redirect - A redirect has been issued and we
 *                                            received request_id via query string
 *                                            complete the payment against our API.
 * @property {CustomEvent} capture_ko - Capturer-style failed to capture the
 *                                      card (GooglePay, ApplePay, SamsungPay,
 *                                      FastPay)
 * @property {CustomEvent} set_extra_data - Launch this event at any point to
 *                                          send extra_data property with any
 *                                          data required (address to be
 *                                          checked against later...)
 * @property {CustomEvent} set_extra_headers - Launch this event at any point to
 *                                          set custom headers that will be
 *                                          sent with each request against the
 *                                          json-rpc backend
 */

/** Payment Wall
 *
 * @class PaymentWall
 * @description Payment Wall object
 * @exports PaymentWall
 *
 * */
export default class PaymentWall {
  /** Initialize a PaymentWall instance over a specific element, amount, url and currency
   *
   * @param {PwallConfiguration} [configuration=currentScript.dataset] - Configuration parameters.
   *
   * @example <caption>Loading the script with data-elements</caption>
   * // Having currentScript.dataset as default configuration dictionary allows us to load the script as:
   * // <script src="..your_loader_script.." data-currency=EUR data-amount=10></script>
   * window.pwall = new PaymentWall()
   *
   * @returns PaymentWall - A PaymentWall instance.
   */

  getRandomUuid() {
    function toHexString(bytes) {
      return bytes.map(function (byte) {
        return (byte & 0xFF).toString(16)
      }).join('')
    }


    // Maps for number <-> hex string conversion
    var _byteToHex = [];
    var _hexToByte = {};
    for (var i = 0; i < 256; i++) {
      _byteToHex[i] = (i + 0x100).toString(16).substr(1);
      _hexToByte[_byteToHex[i]] = i;
    }

    // **`parse()` - Parse a UUID into it's component bytes**
    function parse(s, buf, offset) {
      var i = (buf && offset) || 0;
      var ii = 0;

      buf = buf || [];
      s.toLowerCase().replace(/[0-9a-f]{2}/g, function (oct) {
        if (ii < 16) { // Don't overflow!
          buf[i + ii++] = _hexToByte[oct];
        }
      });

      // Zero out remaining bytes if string was short
      while (ii < 16) {
        buf[i + ii++] = 0;
      }

      return buf;
    }

    function uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    let randomUuid = crypto.randomUUID ? crypto.randomUUID() : uuidv4();

    //return toHexString(parse(randomUuid))

    return randomUuid;
  }

  constructor(configuration = document.currentScript.dataset) {
    /*
     *
     * Construct new paymentWall objec
     * Mandatory params for a session-based integration:
     *
     * - sessionId
     * - amount
     *
     *
     * Optional "recommended" params for a session-based integration
     *
     * - logo
     * - language
     * - currency
     * - placeholder (defaults to #pwall_container), can be either an HTMLObject or a query selector
     * - theme
     * - method
     * - on (list of events => functions to attach to them)
     *
     * After instanciating, you can setup the required scripts, and initialize
     * events in the placeholder with
     * a call to `init`.
     */
    this.configuration = configuration;
    this.amount = configuration.amount || 0;
    this.logo = configuration.logo || 0;
    this.sessionId = configuration.session_id || false;
    // DO not break backwards compatibility, but allow it camel-case as per
    // js standards.
    if (configuration.sessionId) {
      this.sessionId = configuration.sessionId;
    }
    let lang = (navigator.language || navigator.userLanguage).substr(0, 2);
    this.language = configuration.language || lang;
    this.currency = configuration.currency || "EUR";
    this.placeholder = configuration.placeholder || "#pwall-container";
    this.backoffice = configuration.backoffice || false;
    this.tags = configuration.tags || null;
    this.profile = configuration.profile || 'product_page';
    this.redirect = configuration.redirect || false;
    this.theme = configuration.theme || null;
    this.method = configuration.method || 'fastpay';
    this.oneClickPayment =
      configuration.oneClickPayment === "true" ||
      configuration.oneClickPayment === true ||
      false;
    this.catcher =
      configuration.catcher === "true" ||
      configuration.catcher === true ||
      false;
    this.binProfile = configuration.binProfile || false;

    this.sessionLoaded = false;
    this.sessionType = 'capture';
    this.processRedirectLocked = false;
    this.processRetry = false;
    this.cart = {};
    this.session = {};

    this.environment = configuration.environment;

    if (configuration.url || configuration.environment) {
      // If url or environment is specified in config, use them.
      this.url = configuration.url || `https://${configuration.environment}.sipay.es`;
    } else {
      // Otherwise use relative urls.
      this.url = '';
    }

    if (this.sessionId) {
      this.ep = `/payment/api/v1/${this.sessionId}/${this.method}/actions`;
    } else {
      this.ep = '/';
    }
    if (!this.sessionId) {
      // Generate an objectId
      this.sessionId = this.getRandomUuid();
    }

    // i18n texts.
    this.frontSettings = {};

    /** RPC Client instance.
     *
     * @type {PaymentWallRPC}
     */
    this.rpc = new PaymentWallRPC(
      this.url,
      configuration.endpoint || this.ep,
      configuration.timeout || 20000,
      {},
      configuration.version || 1
    );

    /** Map of payment methods.
     * We require a payment method map between element classes and server-side
     * names (wich will be snake-cased)
     */
    this.paymentMethods = {
      apay: APay,
      gpay: GPay,
      azon: Azon,
      fpay: FPay,
      altp_bankia: AltpBkia,
      altp_bizum: AltpBzum,
      alipay: AltpAlipay,
      transfer1: AltpInespay,
      kakaopay: AltpKakaoPay,
      wechatpay: AltpWeChatPay,
      finanpay: Finanpay,
      altp_paypal_express_checkout: AltpPaypalExpressCheckout,
      spay: Spay
    };

    /** Events to listen on.
     *
     * Front app should listen to this events on placeholder element
     * and react accordingly. The events will be added to the placeholder
     * element
     *
     * All this events will contain a detail property (as CustomEvent) containing
     * either the response or the data to be processed.
     *
     * All events will be preceded by "payment_wall", so that "payment_ok" will be
     * "payment_wall_payment_ok"
     *
     * @example <caption>Dom has been loaded, pwall placeholder element is
     * available. Send loaded event to draw the payment wall</caption>
     *
     * document
     *   .querySelector("#pwall_placeholder")
     *   .dispatchEvent(new CustomEvent("payment_wall_loaded", {}));
     *
     * @example <caption>Set extra data to send in every request under
     * request.extra_data field</caption>
     *
     * document
     *   .querySelector('#pwall_placeholder')
     *   .dispatchEvent(new CustomEvent('payment_wall_set_extra_data', {"extra": "data"}));
     *
     * @example <caption> Set extra headers with wordpress auth </caption>
     *
     * document
     * .querySelector("#pwall_placeholder")
     * .dispatchEvent(
     *   new CustomEvent("payment_wall_set_extra_headers", {
     *     "X-WP-Auth": window.wp_auth
     *   })
     * );
     *
     * @type PwallEvents
     */
    this.events = {
      load: data => new CustomEvent("payment_wall_load", { detail: data }),
      loaded: data => new CustomEvent("payment_wall_loaded", { detail: data }),
      setup: data => new CustomEvent("payment_wall_setup", { detail: data }),
      payment_ok: data =>
        new CustomEvent("payment_wall_payment_ok", { detail: data }),
      payment_ok_dcc: data =>
        new CustomEvent("payment_wall_payment_ok_dcc", { detail: data }),
      payment_ko: data =>
        new CustomEvent("payment_wall_payment_ko", { detail: data }),
      capture_ko: data =>
        new CustomEvent("payment_wall_capture_ko", { detail: data }),
      set_extra_data: data =>
        new CustomEvent("payment_wall_set_extra_data", { detail: data }),
      set_extra_headers: data =>
        new CustomEvent("payment_wall_set_extra_headers", {
          detail: data
        }),
      cancel: data =>
        new CustomEvent("payment_wall_cancel", {
          detail: data
        }),
      process_redirect: data => {
        if (!this.processRetry) {
          return new CustomEvent("payment_wall_process_redirect", {
            detail: data
          });
        }
      },
      execute_redirect: data =>
        new CustomEvent("payment_wall_execute_redirect", {
          detail: data
        }),
      execute_payment: data =>
        new CustomEvent("payment_wall_execute_payment", {
          detail: data
        }),
      processing_payment: data =>
        new CustomEvent("payment_wall_processing_payment", {
          detail: data
        }),
      offers_dcc: data =>
        new CustomEvent("payment_wall_offers_dcc", {
          detail: data
        }),
      drawn: data =>
        new CustomEvent("payment_wall_drawn", {
          detail: data
        }),
      pwall_update_settings: data =>
        new CustomEvent("payment_wall_pwall_update_settings", {
          detail: data
        }),
      update_extra_data: data =>
        new CustomEvent("update_extra_data", {
          detail: data
        }),
      set_validation_function: data =>
        new CustomEvent("payment_wall_set_validation_function", {
          detail: data
        }),
      set_cart_data: data =>
        new CustomEvent("payment_wall_set_cart_data", {
          detail: data
        }),
      cart_data_set: data =>
        new CustomEvent("payment_wall_cart_data_set", {
          detail: data
        }),
      token_created: data =>
        new CustomEvent("payment_wall_catcher_data", {
          detail: data
        }),
      ctp_payment_ok: data =>
        new CustomEvent("payment_wall_ctp_payment_ok", { detail: data }),
    };

    this.methods = [];

    this.callbacks = {
      // Use arrow functions as we really really want to keep this as the pwall
      // instance

      load: data => this._dispatch(this.events.load(data)),
      loaded: data => this._dispatch(this.events.loaded(data)),
      setup: data => this._dispatch(this.events.setup(data)),
      drawn: data => this._dispatch(this.events.drawn(data)),
      payment_ok: data => this._dispatch(this.events.payment_ok(data)),
      set_validation_function: data =>
        this._dispatch(this.events.set_validation_function(data)),
      payment_ok_dcc: data => {
        this._dispatch(this.events.payment_ok_dcc(data));
      },
      payment_ko: data => {
        this._dispatch(this.events.payment_ko(data));
      },
      ko: data => {
        this._dispatch(this.events.capture_ko(data));
      },
      capture_ko: data => {
        this._dispatch(this.events.capture_ko(data));
      },
      processing_payment: data => {
        this._dispatch(this.events.processing_payment(data));
      },
      execute_payment: data => {
        this._dispatch(this.events.execute_payment(data));
      },
      process_redirect: data => {
        this._dispatch(this.events.process_redirect(data));
      },
      offers_dcc: data => {
        this._dispatch(this.events.offers_dcc(data));
      },
      set_extra_data: data => {
        this._dispatch(this.events.set_extra_data(data));
      },
      set_extra_headers: data => {
        this._dispatch(this.events.set_extra_headers(data));
      },
      cancel: data => {
        this._dispatch(this.events.cancel(data));
      },
      set_validation_function: data => {
        this._dispatch(this.events.set_validation_function(data));
      },
      set_validation_function: data => {
        this._dispatch(this.events.set_validation_function(data));
      },
      update_extra_data: data => {
        this.rpc.pwall
          .getExtraData(data)
          .then(data => this.callbacks.set_extra_data(data));
      },
      set_cart_data: data => {
        this._dispatch(this.events.set_cart_data(data));
      },
      cart_data_set: data => {
        this._dispatch(this.events.cart_data_set(data));
      },
      pwall_update_settings: data => {
        this._dispatch(this.events.pwall_update_settings(data));
      },
      token_created: data => {
        this._dispatch(this.events.token_created(data));
      },
      ctp_payment_ok: data => this._dispatch(this.events.ctp_payment_ok(data))
    };
    console.debug("[pwall_sdk] Loading setup event");

    // Wait for event to be dispatched.
    // This event should be dispatched when the placeholder DIV is available
    document.addEventListener(this.events.load().type, this.setup.bind(this));

    this.default_events = configuration.on || null;
  }

  /** Listen to a specific event
   *
   * @example <caption>Do something when pwall has been loaded</caption>
   *
   * window.Pwall.listenTo('payment_wall_loaded', console.log)
   *
   * @type PwallEvents
   */
  static listenTo(placeholder, eventName, callback) {
    placeholder.addEventListener(eventName, callback);
  }

  /* Load payment pwall scripts
   *
   * this loads:
   *
   * - pwall_app.js
   * - pwall_app.css
   */
  async loadScripts() {
    await Promise.all([
      include(`https://${this.environment}.sipay.es/pwall_app/js/app.js`, {}, this.configuration),
      includeCss(`https://${this.environment}.sipay.es/pwall_app/css/app.css`)]);
  }

  /*
   *
   * Enable in-one-function start
   *
   */
  _init() {
    // Load scripts.
    if (this.redirect) {
      let defaults = {};
      if (this.session.redirect_after.form_notifications) {
        defaults['paymentOk'] = () => {
          form = document.createElement('form'); form.action = this.session.redirect_after.success;
          form.method = 'post';
          document.body.appendChild(form); form.submit()
        };
        defaults['paymentCancel'] = () => {
          form = document.createElement('form'); form.action = this.session.redirect_after.cancel;
          form.method = 'post';
          document.body.appendChild(form); form.submit()
        };
        defaults['paymentKo'] = () => {
          form = document.createElement('form'); form.action = this.session.redirect_after.failure;
          form.method = 'post';
          document.body.appendChild(form); form.submit()
        }
      } else {
        defaults['paymentOk'] = () => { window.location.href = this.session.redirect_after.success; }
        defaults['paymentCancel'] = () => { window.location.href = this.session.redirect_after.cancel; }
        defaults['paymentKo'] = () => { window.location.href = this.session.redirect_after.failure; }
      }
      this.default_events['ok'] = this.default_events['ok'] || defaults.paymentOk;
      this.default_events['ko'] = this.default_events['ko'] || defaults.paymentKo;
      this.default_events['cancel'] = this.default_events['cancel'] || defaults.paymentCancel;
    }

    this.loadScripts().then(() => {
      // Setup placeholder
      this.setup()

      // Bind events to placeholder
      if (this.default_events) {
        for (const [eventname, func] of Object.entries(this.default_events)) {
          this.placeholder.addEventListener(eventname, func);
        }
      }

      // Start pwall
      PaymentWall.start()
    })
  }

  init() {
    document.addEventListener("DOMContentLoaded", this._init.bind(this));
    return this
  }

  /* Process redirect shortcut */

  static authentication(data) {
    return this.dispatch('proess_redirect', { detail: data });
  }

  static start(data) {
    if (this.prototype.preStartHook) {
      this.prototype.preStartHook(this);
    }
    return document.dispatchEvent(
      new CustomEvent("payment_wall_load_app", { detail: { data: data, settings: this.configuration } })
    );
  }

  /** Dispatch event
   * This is equivalent to launching an event directly with
   * Pwall.events[event](data), except it also accepts a main load event that
   * is listening on DOM's document
   *
   * @example <caption>Request initial payment wall loading</caption>
   *
   * window.Pwall.dispatch('payment_wall_loaded', {})
   *
   * @type PwallEvents
   */
  dispatch(eventName, data) {
    if (data && data.detail) {
      // Allow for both dispatch('foo', {'bar': 'baz'}) and dispatch('foo,
      // {'detail': {'bar': 'baz}}) syntax
      // This allows us to be compatible with customEvents, internal usage for
      // event reporting and "direct" syntax that seems to be used by
      // some obscure plugins
      data = data.detail;
    }
    console.log(`Calling ${eventName}`);
    return this.callbacks[eventName](data);
  }

  /** Internal dispatch event.
   *
   * Dummy method to provide old browsers with dispatchEvent.
   *
   * If a session-id has been set, calls notifyEvent on rpc
   * to notify front events if defined in "when" calls in session API
   * **/
  _dispatch(event_) {
    if (this.rpc && this.sessionId) {
      try {
        data = event_._detail;

        try {
          JSON.stringify(event_._detail);
        } catch {
          data = {}
        }

        this.rpc.pwall
          .notifyEvent({ name: event_.type, extra_data: event_.detail })
          .then();
      } catch { }
    }
    return this.placeholder.dispatchEvent(event_);
  }

  setup() {
    if (!(this.placeholder instanceof HTMLElement)) {
      console.debug(
        `[pwall_sdk] Extracting placeholder from query selector ${this.placeholder}`
      );
      this.placeholder = document.querySelector(this.placeholder);
    }
    console.debug(`[pwall_sdk] Setting up in ${this.placeholder}`);

    // Build wall when loaded event has been launched
    this.placeholder.addEventListener(
      this.events.loaded().type,
      this.buildWall.bind(this),
      {
        once: true
      }
    );

    // Add an event to set extra data to each request.
    // This will allow us to send SESSION data on CMS and custom integrations
    this.placeholder.addEventListener(
      this.events.set_extra_data().type,
      function (event) {
        this.extraData = event.detail;
      }.bind(this)
    );

    // Add an event to set cart data to each request.
    // This will allow us to send SESSION data on CMS and custom integrations
    this.placeholder.addEventListener(
      this.events.set_cart_data().type,
      function (event) {
        this.cartData = event.detail;
      }.bind(this)
    );

    // window.pwall.callbacks.execute_payment({url: url});
    this.placeholder.addEventListener(
      this.events.execute_payment().type,
      async event => {
        this.dispatch("processing_payment", {});
        await this.rpc.pwall.getExtraData({
          express: false,
          create_order: true
        });
        window.location.href = event.detail.url;
      }
    );

    if (this.events.process_redirect()) {
      // Add an event to finish payment with pwall_sdk on redirect options.
      this.placeholder.addEventListener(
        this.events.process_redirect().type,
        event => {
          if (this.processRedirectLocked && !this.processRetry) {
            console.log("Retrying transaction, don't try to process redirect");
            return;
          }
          let pay_method = event.detail.method || "fpay";
          if (pay_method == "pwall_3ds" || pay_method == "altp") {
            pay_method = "fpay";
          }
          const method = this.methods.filter(x => x.name == pay_method)[0];
          method.paymentData = event.detail;
          if (!this.processRetry) {
            method.finish();
          } else {
            this.processRetry = false;
            document.dispatchEvent(new CustomEvent("payment_wall_load_app"));
          }
        }
      );
    }

    // Add an event to setup validation function
    this.placeholder.addEventListener(
      this.events.set_validation_function().type,
      event => {
        console.log("Set validation function called");
        this.validationFunc = event.detail.validationFunc;
      }
    );
    // Add an event to set custom headers to each request.
    // Some authentication mechanisms require this
    this.placeholder.addEventListener(
      this.events.set_extra_headers().type,
      event => {
        this.rpc.customHeaders = event.detail;
      }
    );

    // Finally, notify document that pwall has been set-up
    // and is ready for, for example, ok/ko callbacks on altps
    document.dispatchEvent(this.events.setup());
  }

  /** Set configuration from remote endpoint
   *
   * This will load configuration making a "getConfiguration" json-rpc call
   *
   * Integrator-side should catch this, complete it with key elements as
   * described in integration docs, and sign it.
   *
   * TODO: To fix having to wait until drawn() has been launched once
   * each execution, we could add a buffer of events requested before
   * that initialization step, and execute them on drawn()
   *
   * @returns null
   * */
  async configure() {
    // Get rpc methods before usage.
    await this.rpc.getMethods();
    if (this.sessionId && !this.sessionLoaded) {
      const sess = await this.rpc.pwall.getSession();
      if (sess.amount) {
        this.amount = sess.amount;
        this.sessionLoaded = true;
        this.sessionType = sess.type;
        this.currency = sess.currency;
        this.cart = sess.cart;
        this.session = sess;

        console.log('Dispatching session')
        await this.dispatch('cart_data_set', { detail: sess })
      }
    }

    const config = await this.rpc.pwall.getConfiguration({
      tags: this.tags,
      is_backoffice: this.backoffice == "true",
      profile: this.profile,
      language_id: this.language
    });

    this.frontSettings = config.payment_wall_settings;
    delete config.payment_wall_settings;
    for (let method in config) {
      try {
        if (this.paymentMethods.hasOwnProperty(method)) {
          this.methods.push(
            new this.paymentMethods[method](config[method], this)
          );
        } else {
          if (!NOT_DRAWABLE.includes(method)) {
            console.error(
              `Cant initialize ${method}. We dont currently support it`
            );
          }
        }
      } catch (err) {
        console.error(
          `Cant initialize ${method}. An error ocurred (${err})`,
          err ? err.stack : {}
        );
      }
    }
  }

  /** Build wall to expose it to front-end app
   *
   * It paralelizes method execution after downloading the config with
   * a get_configuration call
   *
   * @example <caption> Build Wall with custom render function </caption>
   * methods = await this.buildWall()
   * for (method in methods) {
   *   method = await methods[method].execute()
   *   for (template in method.elements) {
   *     await custom_render_func(
   *       method.name,
   *       template.html,
   *       template.title,
   *       template.icon
   *     );
   *   }
   *   await method.setup();
   * }
   * @example <caption> Build Wall with any data-binding app </caption>
   * mounted() {
   *   methods = await this.buildWall()
   *   for (method in methods) {
   *     method = await methods[method].execute()
   *     for (template in method.elements) {
   *       this.data.methods.append(template);
   *     }
   *     await method.setup();
   *   }
   * }
   */
  async buildWall() {
    await this.configure();

    // filter methods to prevent duplicates in pwall_app for events concurrency
    const uniqueArray = this.methods.filter((item, index) => {
      return (
        this.methods
          .map(function (e) {
            return e.name;
          })
          .indexOf(item.name) === index
      );
    });

    // Dispatch drawn event with all methods rendered
    this._dispatch(
      this.events.drawn({
        frontSettings: this.frontSettings,
        methods: await Promise.all(uniqueArray.map(method => method.execute()))
      })
    );
  }
}

if (typeof window.CustomEvent !== "function") {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  global.CustomEvent = CustomEvent;
  global.Event = CustomEvent;
}

global.PaymentWall = PaymentWall;
