import PaymentWallMethod from "./base.js";
import { transformDecimals } from "../utils.js";
import { getCookie } from "../utils.js";

/** Payment wall altp bankia method.
 *
 * It will render a static redirect button for bankia payments, and it will
 * handle disabled methods with eulerian. See isDisabled for more info
 */
export default class Altp_Bkia extends PaymentWallMethod {
  name = "altp_bankia";

  async setUp() {}

  /** Check if eulerian cookie has disabled any of the bankia methods.
   * If Eulerian cookie can't be read in a sensible amount of time (500ms), it will disable all bankia payment methods.
   */
  async isDisabled() {
    // Eulerian static code, minified and provided by them
    let resolve_func = null;

    const promise = new Promise(function(resolve, reject) {
      resolve_func = resolve;
    });

    function eulerianLoadPwall() {
      const cookie = getCookie("cbk");
      console.log(`[altp_bkia] received cookie ${cookie}`);

      if (cookie) {
        console.debug(`[altp_bkia] "CBK" cookie detected with value ${cookie}`);
        const disabled = [];
        if (cookie[0] == "0") {
          console.log(`[altp_bkia] Disabling transfer`);
          disabled.push("altp_bankia_transfer");
        }

        if (cookie[1] == "0") {
          console.log(`[altp_bkia] Disabling finance`);
          disabled.push("altp_bankia_finance");
        }
        resolve_func(disabled);
      }
    }

    if (!this.settings.templates[0].settings.bankia_opt_in) {
      const binded = eulerianLoadPwall.bind(this);
      console.debug("[altp_bkia] Adding eulerian event listener");

      document.body.addEventListener("eulerian_load_pwall", binded);
      setTimeout(function() {
        // Set timeout to 500 ms, at that point we won't do anything if
        // eulerian_load_pwall is fired
        // Also, we'll disable bankia redirect, as we don't have cookie information
        window.removeEventListener("eulerian_load_pwall", binded);
        console.debug(
          "[altp_bkia] Timeout on eulerian event. Disabling not-already-rendered methods"
        );
        resolve_func(["altp_bankia_finance", "altp_bankia_transfer"]);
      }, 700);
    } else {
      let disabled = [];
      if (this.settings.templates[0].settings.bkia_opt_in_finance_amount && this.paymentWall.amount < this.settings.templates[0].settings.bkia_opt_in_finance_amount) {
        disabled.push("altp_bankia_finance");
        console.log(`[altp_bkia] Disabling finance does not meet min amount required`);
      }
      resolve_func(disabled);
    }

    window.EA_data = [
      "path",
      "COMERCIO",
      "enotagdt",
      "1",
      "price",
      transformDecimals(
        this.paymentWall.amount,
        this.paymentWall.currency
      ).toString()
    ];

    (function() {
      var td = "bhu9.bankia.es",
        d = document,
        l = d.location;
      if (!l.protocol.indexOf("http")) {
        var o = d.createElement("script"),
          a = d.getElementsByTagName("script")[0],
          cn = parseInt(new Date().getTime() / 3600000),
          cj = "",
          cdh = (l.host + td).replace(/[^a-z]/g, ""),
          cdh = (l.host + td).replace(/[^a-z]/g, ""),
          cdr = cdh + cdh.toUpperCase(),
          acdr = cdr.split("");
        for (var i = -1; i < cn % 7; i++) {
          cj += acdr[(cn + i) % acdr.length];
        }
        o.type = "text/javascript";
        o.async = "async";
        o.defer = "defer";
        o.src = "//" + td + "/" + cj + (cn % 8760) + ".js";
        a.parentNode.insertBefore(o, a);
      }
    })();

    return await promise;
  }
}
