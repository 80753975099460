import axios from "axios";

const reducer = api =>
  function(obj, m) {
    obj[m] = (...args) => this._rpc(api + "." + m, ...args);
    return obj;
  };

/** Payment Wall RPC Client
 *
 * @class PaymentWallRPC
 * @description Payment Wall RPC Client.
 *              Will handle all requests against Payment Wall backend
 * @exports PaymentWallRPC
 *
 */

export default class PaymentWallRPC {
  /** Create a payment wall rpc instance with sensitive defaults
   *
   * @param {string} host - Base host for RPC queries
   * @param {int} [timeout=5000] - Default requests timeout.
   * @param {object} [customHeaders={}] - Custom headers to send in each
   *                                      request
   * @param {int} [version=1] - PWall API Version
   *
   * */
  constructor(host, endpoint, timeout, customHeaders = {}, version = 1) {
    this.host = host;
    this.endpoint = endpoint;
    this.timeout = timeout;
    this.customHeaders = customHeaders;
    this.version = version;

    this.currId = 0;

    /** Axios instance @type {axios} **/
    this.axios = axios.create({
      baseURL: host,
      timeout: timeout || 15000
    });
  }

  /** Make an internal RPC call
   *
   * @param {string} action - RPC method
   * @param {object} params - RPC params
   */
  async _rpc(action, params = null) {
    const sent_id = ++this.currId;
    let returnDirect = false;

    if (params && params.returnDirect) {
      returnDirect = params.returnDirect;
      delete params.returnDirect;
    }

    const response = await this.axios.post(
      this.endpoint,
      {
        jsonrpc: "2.0",
        id: sent_id,
        action: action,
        params: { ...params, version: this.version }
      },
      { headers: { "Content-Type": "application/json", ...this.customHeaders } }
    );

    if (response.data.error) {
      return response.data
    }

    if (!response.data.id) {
      throw "Invalid response from server";
    }
    if (response.data.force_url_redirect) {
      window.location.href = response.data.force_url_redirect;

    }
    if (sent_id != response.data.id) {
      throw "Inconsistency in JSONRPC ids";
    }

    if (returnDirect) {
      return response.data.result;
    }

    if (
      response.data.result.payload &&
      Object.keys(response.data.result.payload).length
    ) {
      // We got a payload in the response
      return response.data.result.payload;
    } else {
      // No payload, return the response directly as it might contain error data
      return response.data.result;
    }
  }

  /** Extract available API methods
   *
   * @param {string} method - RPC method
   * @param {object} params - RPC params
   */
  async getMethods() {
    let apis = await this._rpc("rpc.listMethods");
    for (let api in apis) {
      this[api] = apis[api].reduce(reducer(api).bind(this), {});
    }
  }
}
