import PaymentWallMethod from "./base.js";
import {transformDecimals} from "../utils.js";


const allowedCardAuthMethods = [/* "PAN_ONLY", */ "CRYPTOGRAM_3DS"]
const allowedCardNetworks = ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"]

export class SipayGooglePay {
  constructor(params) {
    params.total_price = transformDecimals(
      params.total_price,
      params.currency_code
    ).toString();
    this.settings = {
      allowed_card_auth_methods: allowedCardAuthMethods,
      allowed_card_networks: allowedCardNetworks,
      merchant_id: null,
      google_merchant_id: null,
      gateway_name: params.gateway_name,
      google_merchant_name: null,
      test_env: true,
      currency_code: "EUR",
      total_price: null,
      total_price_status: "FINAL",
      total_price_status_prefetch: "NOT_CURRENTLY_KNOWN",
      callback_ok: params.callback_ok,
      callback_ko: params.callback_ko,
      element: "#google_pay_button",
      button: null,
      ...params
    };

    this.baseCardPaymentMethod = {
      type: "CARD",
      parameters: {
        allowedAuthMethods: this.settings.allowed_card_auth_methods,
        allowedCardNetworks: this.settings.allowed_card_networks
      }
    };
  }

  initializeAll(callback) {
    this.initialize();
    this.onGooglePayLoaded(callback);
  }

  /**
   * Configure support for the Google Pay API
   *
   * @see {@link
   * https://developers.google.com/pay/api/web/reference/object#PaymentDataRequest|PaymentDataRequest}
   *
   * @returns {object} PaymentDataRequest fields
   */
  getGooglePaymentDataRequest() {
    return {
      allowedPaymentMethods: [
        {
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: this.settings.gateway_name,
              gatewayMerchantId: this.settings.merchant_id
            }
          },
          ...this.baseCardPaymentMethod
        }
      ],
      transactionInfo: {
        currencyCode: this.settings.currency_code,
        totalPriceStatus: this.settings.total_price_status,
        totalPrice: this.settings.total_price
      },
      merchantInfo: {
        merchantId: this.settings.google_merchant_id,
        merchantName: this.settings.google_merchant_name
      },
      apiVersion: 2,
      apiVersionMinor: 0
    };
  }

  initialize() {
    this.paymentsClient = new google.payments.api.PaymentsClient({
      environment: this.settings.test_env ? "TEST" : "PRODUCTION"
    });

    this.onGooglePayLoaded = async callback => {
      try {
          const button = this.paymentsClient.createButton({
            onClick: async () => {
              this.pay();
            }
          });
          let elem = document.querySelector(this.settings.element);
          if (!elem) {
            console.error(
              `[gpay] Can't render. ${this.settings.element} not found`
            );
            if(!window.pwall.oneClickPayment){
              this.settings.callback_ko({error: "Internal DOM error"});
            }
          }else{
            elem.setAttribute("type", "button");
            elem.appendChild(button);
            this.settings.button = button;
            if (callback) {
              callback();
            }
          }
      } catch (err) {
        this.settings.callback_ko(err);
      }
    };

    /**
     * Prefetch payment data to improve performance
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
     */
    function prefetchGooglePaymentData() {
      this.paymentsClient.prefetchPaymentData({
        transactionInfo: {
          totalPriceStatus: this.settings.total_price_status_prefetch,
          currencyCode: this.settings.currency_code
        },
        ...this.getGooglePaymentDataRequest()
      });
    }
  }

  async pay(){
    try {
      window.pwall.placeholder.dispatchEvent(new Event("payment_wall_processing_payment"))
      const result = await this.paymentsClient.loadPaymentData({
        transactionInfo: {
          currencyCode: this.settings.currency_code,
          totalPriceStatus: this.settings.total_price_status,
          totalPrice: this.settings.total_price
        },
        ...this.getGooglePaymentDataRequest()
      });
      try {
        await window.pwall.rpc.pwall.getExtraData({
          express: false,
          create_order: true
        });
      } catch {
        console.log("Could not call getExtraData")
      }
      window.pwall.placeholder.dispatchEvent(new Event("payment_wall_stop_processing_payment"))
      this.settings.callback_ok(result);
    } catch (err) {
      window.pwall.placeholder.dispatchEvent(new Event("payment_wall_stop_processing_payment"))
      console.log(err);
    }
  }

}

export default class GPay extends PaymentWallMethod {
  name = "gpay";
  oneClickAvailable = true;

  getPaymentData() {
    return {token_gpay: this.result.paymentMethodData.tokenizationData.token};
  }

  async setUp() {
    this.paymentWall.gpay = new SipayGooglePay({
      total_price: this.paymentWall.amount,
      currency_code: this.paymentWall.currency,
      callback_ok: async result => {
        try {
          this.result = result;
          this.paymentWall.callbacks.processing_payment();
          let response = await this.paymentWall.rpc.pwall.sale({
              method: this.name,
              returnDirect: true,
              ...this.paymentWall.extraData,
              ...this.getPaymentData()
            });
          if(response.code == "0" && response.payload.url){
            window.location.href = response.payload.url
          }else if(response.code == "-1" && response.payload.status_code == 501){
            this.name = 'gpay_crypto'
          }
          this.finish().then(result => {
            console.debug(`Payment Processed ${result}`);
          });
        } catch {
          this.paymentWall.callbacks.payment_ko();
        }
      },
      callback_ko: this.paymentWall.callbacks.capture_ko,
      ...this.userSettings
    });
    this.paymentWall.gpay.initializeAll();
  }

  async oneClickPayment(){
    this.paymentWall.gpay.pay();
  }

  async isDisabled() {
    //Disabled if isReadyToPay returns false
    let paymentsClient = new google.payments.api.PaymentsClient({
      environment: this.userSettings.test_env ? "TEST" : "PRODUCTION"
    });
    let response = await paymentsClient.isReadyToPay({
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [{
        type: "CARD",
        parameters: {
          allowedAuthMethods: this.userSettings.allowed_card_auth_methods || allowedCardAuthMethods,
          allowedCardNetworks: this.userSettings.allowed_card_networks || allowedCardNetworks
        }
      }]
    });
    if (!response.result) {
      return ["gpay"];
    } else {
      return [];
    }
  }
}
