import PaymentWallMethod from "./base.js";
const VALID_CODES = ["0"];


/** AltpAlipay payment wall method
 * @class AltpAlipay
 * @description Implementation of AltpAlipay for the payment wall
 * @exports AltpAlipay
 */

 export default class AltpAlipay extends PaymentWallMethod {
    /** Set
      console.log(event.detail);
      console.log(event.detail);Up for alipay. Nothing special needs
     *  to be done */
    async setUp() {}
  
    name = "alipay";
  
    /** Execute a payment against jsonrpc
     *
     * Response will be evaluated, valid codes are defined in {VALID_CODES}
     * payment_ok or payment_ko will be launched
     *
     * @returns callback execution result
     * */
    async pay() {
      const result = await this.paymentWall.rpc.pwall.sale({
        method: this.name,
        returnDirect: true,
        ...this.paymentWall.extraData,
        ...this.getPaymentData()
      });
  
      if (VALID_CODES.includes(result.code)) {
        return this.paymentWall.callbacks.payment_ok(result.response);
      } else {
        return this.paymentWall.callbacks.payment_ko({ error: result });
      }
    }
  }
  